// Framework
import angularIcon from "@iconify-icons/logos/angular-icon"
import flutterIcon from "@iconify-icons/logos/flutter"
import nodejsIcon from "@iconify-icons/logos/nodejs"
import springIcon from "@iconify-icons/logos/spring"
import unityIcon from "@iconify-icons/logos/unity"

// qaAutomations
import appiumIcon from "@iconify-icons/logos/appium"
import apachejmeterIcon from "@iconify-icons/simple-icons/apachejmeter"

// Language
import javaIcon from "@iconify-icons/logos/java"
import javascriptIcon from "@iconify-icons/logos/javascript"
import pythonIcon from "@iconify-icons/logos/python"
import golangIcon from "@iconify-icons/grommet-icons/golang"

// Devops
import terraformIcon from "@iconify-icons/logos/terraform"
import ansibleIcon from "@iconify-icons/logos/ansible"
import awsIcon from "@iconify-icons/logos/aws"
import consulIcon from "@iconify-icons/logos/consul"
import dockerIcon from "@iconify-icons/logos/docker"
import gitIcon from "@iconify-icons/logos/git"
import kubernetesIcon from "@iconify-icons/logos/kubernetes"
import jenkinsIcon from "@iconify-icons/logos/jenkins"
import nomadIcon from "@iconify-icons/logos/nomad"
import prometheusIcon from "@iconify-icons/logos/prometheus"
import fluentdIcon from "@iconify-icons/simple-icons/fluentd"
import grafanaIcon from "@iconify-icons/logos/grafana"

// Data Analysis
import airflowIcon from "@iconify-icons/logos/airflow"
import apacheSpark from "@iconify-icons/cib/apache-spark"
import apachenetbeansideIcon from "@iconify-icons/simple-icons/apachenetbeanside"

// Database
import mongodbIcon from "@iconify-icons/logos/mongodb"
import cassandraIcon from "@iconify-icons/logos/cassandra"
import sqlIcon from "@iconify-icons/carbon/sql"
import redisIcon from "@iconify-icons/logos/redis"
import postgresqlIcon from "@iconify-icons/logos/postgresql"
import elasticsearchIcon from "@iconify-icons/logos/elasticsearch"

export const databases = [
  {
    id: 1,
    image: mongodbIcon,
  },
  {
    id: 2,
    image: cassandraIcon,
  },
  {
    id: 3,
    image: elasticsearchIcon,
  },
  {
    id: 5,
    image: sqlIcon,
  },
  {
    id: 6,
    image: redisIcon,
  },
  {
    id: 7,
    image: postgresqlIcon,
  },
]

export const dataAnalysises = [
  {
    id: 1,
    image: airflowIcon,
  },
  {
    id: 2,
    image: apachenetbeansideIcon,
  },
  {
    id: 3,
    image: apacheSpark,
  },
]

export const devopses = [
  {
    id: 1,
    image: ansibleIcon,
  },
  {
    id: 2,
    image: awsIcon,
  },
  {
    id: 3,
    image: consulIcon,
  },
  {
    id: 4,
    image: dockerIcon,
  },
  {
    id: 5,
    image: gitIcon,
  },
  {
    id: 6,
    image: grafanaIcon,
  },
  {
    id: 7,
    image: kubernetesIcon,
  },
  {
    id: 8,
    image: jenkinsIcon,
  },
  {
    id: 9,
    image: nomadIcon,
  },
  {
    id: 10,
    image: prometheusIcon,
  },
  {
    id: 11,
    image: fluentdIcon,
  },
  {
    id: 12,
    image: terraformIcon,
  },
]

export const languages = [
  {
    id: 1,
    image: javaIcon,
  },
  {
    id: 2,
    image: javascriptIcon,
  },
  {
    id: 3,
    image: pythonIcon,
  },
  {
    id: 4,
    image: golangIcon,
  },
]

export const qaAutomations = [
  {
    id: 1,
    image: appiumIcon,
  },
  // {
  //   id: 2,
  //   image: beanshell,
  // },
  {
    id: 3,
    image: apachejmeterIcon,
  },
  // {
  //   id: 4,
  //   image: assured,
  // },
]

export const frameworks = [
  {
    id: 1,
    image: angularIcon,
  },
  {
    id: 2,
    image: flutterIcon,
  },
  {
    id: 3,
    image: nodejsIcon,
  },
  {
    id: 4,
    image: springIcon,
  },
  {
    id: 5,
    image: unityIcon,
  },
]
