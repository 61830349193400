import React, { useEffect } from "react"
import { Text } from "@chakra-ui/layout"
import { Box } from "@chakra-ui/layout"
import image1 from "../../../images/timeline/Code_thinking.svg"
import image2 from "../../../images/timeline/interaction_design.svg"
import image3 from "../../../images/timeline/interview.svg"
import image4 from "../../../images/timeline/Meeting.svg"
import image5 from "../../../images/timeline/mobile_testing_reah.svg"
import { Image } from "@chakra-ui/image"
import "./Timeline.css"
import { useState } from "react"

const Timeline = () => {
  const [aosWidth, setAosWidth] = useState(false)

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.outerWidth < 768) {
        setAosWidth(true)
      } else {
        setAosWidth(false)
      }
    })
  }, [aosWidth])

  return (
    <Box id="timelineId" className="sideMenuId">
      <Text
        fontWeight="700"
        textAlign="center"
        color="var(--secondary-headingColor)"
        fontSize="var(--secondary-heading)"
      >
        How we work
      </Text>
      <Text
        w="100px"
        h="5px"
        className="gradient__underHeading"
        margin="0 auto"
        borderRadius="10px"
      ></Text>
      <Box className="timeline">
        <Box className="timeline__box">
          <Box
            data-aos={!aosWidth ? "fade-right" : "fade-zoom-in"}
            className="timeline__textBox timeline__text-left"
          >
            <Text className="timeline__text">Share your idea</Text>
            <Text fontSize="16px" fontWeight="300">
              We discuss all expectation with client to deliver a dream
              solution.
            </Text>
          </Box>
          <Image
            data-aos="fade-left"
            src={image1}
            className="timeline__image timeline__imgRight"
          />
        </Box>

        <Box className="timeline__box">
          <Image
            data-aos="fade-right"
            src={image2}
            className="timeline__image timeline__imgLeft"
          />
          <Box
            data-aos={!aosWidth ? "fade-left" : "fade-zoom-in"}
            className="timeline__textBox"
          >
            <Text className="timeline__text">Design</Text>
            <Text fontSize="16px" fontWeight="300">
              We create an efficient and user-friendly design through an
              iterative and interactive process.
            </Text>
          </Box>
        </Box>

        <Box className="timeline__box">
          <Box
            data-aos={!aosWidth ? "fade-right" : "fade-zoom-in"}
            className="timeline__textBox timeline__text-left"
          >
            <Text className="timeline__text">Code development</Text>
            <Text fontSize="16px" fontWeight="300">
              We develop application based on client’s requirement
            </Text>
          </Box>
          <Image
            data-aos="fade-left"
            src={image3}
            className="timeline__image timeline__imgRight"
          />
        </Box>

        <Box className="timeline__box">
          <Image
            data-aos="fade-right"
            src={image4}
            className="timeline__image timeline__imgLeft"
          />
          <Box
            data-aos={!aosWidth ? "fade-left" : "fade-zoom-in"}
            className="timeline__textBox"
          >
            <Text className="timeline__text">Testing</Text>
            <Text fontSize="16px" fontWeight="300">
              Fixing all errors with thorough testing for best user experience
            </Text>
          </Box>
        </Box>

        <Box className="timeline__box">
          <Box
            data-aos={!aosWidth ? "fade-right" : "fade-zoom-in"}
            className="timeline__textBox timeline__text-left"
          >
            <Text className="timeline__text">Deliver</Text>
            <Text fontSize="16px" fontWeight="300">
              We deliver solution only after ensuring its flawless functioning.
            </Text>
          </Box>
          <Image
            data-aos="fade-left"
            src={image5}
            className="timeline__image timeline__imgRight"
          />
        </Box>
        <span className="timeline__bullet bullet_1"></span>
        <span className="timeline__bullet bullet_2"></span>
        <span className="timeline__bullet bullet_3"></span>
        <span className="timeline__bullet bullet_4"></span>
        <span className="timeline__bullet bullet_5"></span>
      </Box>
    </Box>
  )
}
export default Timeline
