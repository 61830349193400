import React, { useState } from "react"
import { Box, Text } from "@chakra-ui/layout"
import {
  languages,
  frameworks,
  databases,
  dataAnalysises,
  devopses,
  qaAutomations,
} from "./TechnologyStackData"
import { Image } from "@chakra-ui/image"
import { Icon } from "@iconify/react"
import "./TechnologyStack.css"
import scylla from "../../../images/technology-stack/Scylla.svg"
import griffin from "../../../images/technology-stack/griffin.svg"
import redshift from "../../../images/technology-stack/redshift.png"
import restassured from "../../../images/technology-stack/restassured.png"

const TechnologyStack = () => {
  const initialState = {
    language: true,
    framework: false,
    database: false,
    dataAnalysis: false,
    devops: false,
    qaAutomation: false,
  }
  const [data, setData] = useState(initialState)
  return (
    <Box
      data-aos="fade-up"
      maxW="940px"
      margin="60px auto"
      p="50px 10px"
      id="technologyStackId"
      className="sideMenuId"
    >
      <Text
        textAlign="center"
        color="var(--secondary-headingColor)"
        fontSize="var(--secondary-heading)"
        fontWeight="700"
      >
        Technology Stack
      </Text>
      <Text
        w="100px"
        h="5px"
        className="gradient__underHeading"
        margin="0 auto"
        borderRadius="10px"
      ></Text>
      <Text fontSize="20px" lineHeight="30px" textAlign="center" mt="30px">
        Our app developers are highly proficient in building an app that works
        seamlessly with the integration of advanced technology and unique
        approach.
      </Text>
      <Box
        textAlign="center"
        m="40px auto"
        display="grid"
        gridTemplateColumns={[
          "repeat(3,1fr)",
          "repeat(3,1fr)",
          "repeat(6,1fr)",
          "repeat(6,1fr)",
        ]}
        flexWrap="wrap"
      >
        <Box
          className={
            data.language
              ? "technology_buttonBox active_buttonBox"
              : "technology_buttonBox"
          }
          onClick={() => setData({ ...initialState, language: true })}
        >
          Language
        </Box>
        <Box
          className={
            data.framework
              ? "technology_buttonBox active_buttonBox"
              : "technology_buttonBox"
          }
          onClick={() =>
            setData({ ...initialState, language: false, framework: true })
          }
        >
          Framework
        </Box>
        <Box
          className={
            data.database
              ? "technology_buttonBox active_buttonBox"
              : "technology_buttonBox"
          }
          onClick={() =>
            setData({ ...initialState, language: false, database: true })
          }
        >
          Database
        </Box>
        <Box
          className={
            data.dataAnalysis
              ? "technology_buttonBox active_buttonBox"
              : "technology_buttonBox"
          }
          onClick={() =>
            setData({ ...initialState, language: false, dataAnalysis: true })
          }
        >
          Data Analysis
        </Box>
        <Box
          className={
            data.devops
              ? "technology_buttonBox active_buttonBox"
              : "technology_buttonBox"
          }
          onClick={() =>
            setData({ ...initialState, language: false, devops: true })
          }
        >
          Devops
        </Box>
        <Box
          className={
            data.qaAutomation
              ? "technology_buttonBox active_buttonBox"
              : "technology_buttonBox"
          }
          onClick={() =>
            setData({ ...initialState, language: false, qaAutomation: true })
          }
        >
          QA automation
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexWrap="wrap"
        mt="40px"
      >
        {data.language &&
          languages.map(item => (
            <Icon
              key={item.id}
              className="technology__icon"
              icon={item.image}
            />
          ))}
        {data.framework &&
          frameworks.map(item => (
            <Icon
              key={item.id}
              className="technology__icon"
              icon={item.image}
            />
          ))}
        {data.database && (
          <>
            {databases.map(item => (
              <Icon
                key={item.id}
                className="technology__icon"
                icon={item.image}
              />
            ))}
            <Image src={scylla} width="120px" />
          </>
        )}
        {data.dataAnalysis && (
          <>
            {dataAnalysises.map(item => (
              <Icon
                key={item.id}
                className="technology__icon"
                icon={item.image}
              />
            ))}
            <Image src={griffin} width="120px" />
            <Image src={redshift} width="120px" />
          </>
        )}
        {data.devops &&
          devopses.map(item => (
            <Icon
              key={item.id}
              className="technology__icon"
              icon={item.image}
            />
          ))}
        {data.qaAutomation && (
          <>
            {qaAutomations.map(item => (
              <Icon
                key={item.id}
                className="technology__icon"
                icon={item.image}
              />
            ))}
            <Image src={restassured} width="60px" />
          </>
        )}
      </Box>
    </Box>
  )
}

export default TechnologyStack
