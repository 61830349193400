import maintainability from "../../images/features/maintenance.png"
import scalability from "../../images/features/scalability.png"
import portability from "../../images/features/portability.png"
import accuracy from "../../images/features/accuracy.png"
import efficiency from "../../images/features/efficiency.png"
import reliability from "../../images/features/reliability.png"

export const featureData = [
  {
    id: 1,
    title: "Maintainability",
    description:
      "Get your info tests delivered at home collect a sample from the your progress tests.",
    image: maintainability,
  },
  {
    id: 2,
    title: "Scalability",
    description:
      "Get your info tests delivered at home collect a sample from the your progress tests.",
    image: scalability,
  },
  {
    id: 3,
    title: "Portability",
    description:
      "Get your info tests delivered at home collect a sample from the your progress tests.",
    image: portability,
  },
  {
    id: 4,
    title: "Accuracy",
    description:
      "Get your info tests delivered at home collect a sample from the your progress tests.",
    image: accuracy,
  },
  {
    id: 5,
    title: "Efficiency",
    description:
      "Get your info tests delivered at home collect a sample from the your progress tests.",
    image: efficiency,
  },
  {
    id: 6,
    title: "Reliability",
    description:
      "Get your info tests delivered at home collect a sample from the your progress tests.",
    image: reliability,
  },
]
