import React from "react"
import Navbar from "../../Utilities/Navbar/Navbar"

const Layout = ({showLogin, children }) => {
  return (
    <div>
      <Navbar showLogin={showLogin}/>
      <div className="layout__content">{children}</div>
    </div>
  )
}

export default Layout
