import { Text } from "@chakra-ui/layout"
import { Box } from "@chakra-ui/layout"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import React from "react"
import { serviceData } from "./ServiceData"
import "./Services.css"

const Services = () => {
  return (
    <Box
      data-aos="fade-up"
      align="center"
      p="15px"
      pt="120px"
      id="serviceId"
      className="sideMenuId"
    >
      <Text
        fontWeight="700"
        color="var(--secondary-headingColor)"
        fontSize="var(--secondary-heading)"
      >
        Services we provide
      </Text>
      <Text fontSize="18px" color="#858B91" mt="10px" mb="60px">
        Features are highlighted here
      </Text>

      <Box className="serviceItem__container">
        {serviceData.map(item => (
          <ServiceItem key={item.id} item={item} />
        ))}
      </Box>

      <Text
        fontSize="24px"
        color="#0f2137"
        textAlign="center"
        fontWeight="700"
        mt="32px"
      >
        Many More
      </Text>
    </Box>
  )
}

export default Services

const ServiceItem = ({ item }) => {
  return (
    <Box className="serviceItem" m="10px" mt="15px">
      <Box className={`${item.class}`} borderRadius="12px">
        <FontAwesomeIcon icon={item.image} className="serviceItem__icon" />
      </Box>
      <Box ml="20px" className="serviceItem__content">
        <Text color="#0f2137" fontSize="18px" fontWeight="700">
          {item.title}
        </Text>
        <Text color="#343D48" lineHeight="30px" mt="10px" mb="20px">
          {item.description}
        </Text>
        <Link className="primary__link" to={item.link}>
          <span className="primary__link--left">Learn more</span>
          <span className="primary__link--right">&gt;</span>
        </Link>
      </Box>
    </Box>
  )
}
