import { Button } from "@chakra-ui/button"
import { Input } from "@chakra-ui/input"
import { Text } from "@chakra-ui/layout"
import { Box } from "@chakra-ui/layout"
import React from "react"
import { useState } from "react"
import swal from "sweetalert"
import "./Footer.css"
import firebase from "../../../firebase"

const Footer = () => {
  const [data, setData] = useState({ email: "" })

  const actionCodeSettings = {
    url: "https://thesensetech.com/",
    handleCodeInApp: true,
  }

  const handleSubscribe = () => {
    firebase
      .auth()
      .sendSignInLinkToEmail(data.email, actionCodeSettings)
      .then(() => {
        swal(
          "Thanks for subscribing",
          "A link will sent you within a few moments. Please check and verify the mail.",
          "success"
        )
        // console.log(res)
        setData({ email: "" })
        window.localStorage.setItem("emailForSignIn", data.email)
      })
      .catch(error => {
        swal("Error", "Please provide a valid email address", "error")
      })

    firebase
      .firestore()
      .collection("subscription")
      .add(data)
      .then(ref => {
        setData({ email: "" })
      })
  }

  return (
    <Box
      p="15px"
      className="footer sideMenuId"
      color="#ffffff"
      textAlign="center"
      pt="100px"
      pb="20px"
      id="footerId"
    >
      <Text fontWeight="400" mb="10px" className="footer__heading">
        Subscribe to get notified about updates
      </Text>
      <Text mb="20px" px="40px">
        By subscribing with your mail, you will accept our privacy policy
      </Text>
      <Box pb="100px" mt="40px" className="footer__inputBox">
        <Input
          placeholder="Enter your email"
          size="md"
          p="10px"
          width="300px"
          _placeholder="#fff"
          outline="none"
          bg="#161b2a"
          color="#ffffff"
          border="none"
          mr="10px"
          borderRadius="5px"
          onChange={e => setData({ email: e.target.value })}
          value={data.email}
        />
        <Button
          onClick={handleSubscribe}
          className="btn__primary"
          style={{ backgroundColor: "#fff", color: "#020718" }}
        >
          Subscribe Us
        </Button>
      </Box>
      <Box maxW="940px" textAlign="center" margin="auto">
        <Text>
          Copyright &copy; {new Date().getFullYear()} SenseTech Innovation
          Private Limited. All rights reserved.
        </Text>
      </Box>
    </Box>
  )
}

export default Footer
