import {
  faCloud,
  faCode,
  faComments,
  faDesktop,
  faMobileAlt,
  faRobot,
} from "@fortawesome/free-solid-svg-icons"

export const serviceData = [
  {
    id: 1,
    title: "RPA",
    description:
      "Automate your processes and get more work done with less resources.",
    image: faRobot,
    link: "/services#rpa",
    background: "#",
    class: "rpa",
  },
  {
    id: 2,
    title: "Mobile Application",
    description:
      "Launching your business application will increase the scope  of your business.",
    image: faMobileAlt,
    link: "/services/#mobile-application",
    background: "#03fca5",
    class: "mobile",
  },
  {
    id: 3,
    title: "Web Application",
    description:
      "We make web solutions for your work . Work , sell , collaborate from anywhere in the world.",
    image: faDesktop,
    link: "/services/#web-application",
    background: "#645cf8",
    class: "desktop",
  },
  {
    id: 4,
    title: "Cloud Support",
    description:
      "We help you to migrate, optimise and strategically support cloud.",
    image: faCloud,
    link: "/services/#cloudSupport",
    background: "#fb7b43",
    class: "cloud",
  },
  {
    id: 5,
    title: "Chatbot",
    description: "Be available for your users around the clock.",
    image: faComments,
    link: "/services/#chatbots",
    background: "#3183ff",
    class: "chatbot",
  },
  {
    id: 6,
    title: "MicroFrontend",
    description:
      "Build simpler and easy to manage architecture where teams can work independently.",
    image: faCode,
    link: "/services/#microfrontend",
    background: "#fc4c80",
    class: "microfrontend",
  },
]
