import { Image } from "@chakra-ui/image"
import { Text } from "@chakra-ui/layout"
import { Box } from "@chakra-ui/layout"
import React, { useEffect } from "react"
import AOS from "aos"
import "aos/dist/aos.css"
import "./Features.css"
import { featureData } from "../../../config/Home/featureData"

const Features = () => {
  useEffect(() => {
    AOS.init({
      offset: 120,
      delay: 0,
      duration: 2000,
      easing: "ease-out",
      once: false,
      mirror: false,
      anchorPlacement: "top-bottom",
    })
  }, [])

  return (
    <Box
      data-aos="fade-up"
      align="center"
      p="15px"
      pt="120px"
      id="featureId"
      className="sideMenuId"
    >
      <Text
        fontWeight="700"
        color="var(--secondary-headingColor)"
        fontSize="var(--secondary-heading)"
      >
        Features of our solutions
      </Text>
      <Text fontSize="18px" color="#858B91" mt="10px" mb="60px">
        Features are highlighted here
      </Text>

      <Box className="featureItem__container">
        {featureData.map(item => (
          <FeatureItem key={item.id} item={item} />
        ))}
      </Box>
    </Box>
  )
}

export default Features

const FeatureItem = ({ item }) => {
  return (
    <Box m="5px" mt="15px">
      <Box className="featureItem">
        <Image className="featureItem__img" src={item.image} />
        <Box mt="20px" className="serviceItem__content">
          <Text
            textAlign="center"
            color="#0f2137"
            fontSize="18px"
            fontWeight="700"
          >
            {item.title}
          </Text>
        </Box>
      </Box>
    </Box>
  )
}
