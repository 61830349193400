import { Button } from "@chakra-ui/button"
import { Image } from "@chakra-ui/image"
import { Text } from "@chakra-ui/layout"
import { Box } from "@chakra-ui/layout"
import React, { useEffect, useState } from "react"
import technology from "../../../images/technology.png"
import "./Technology.css"

const Technology = () => {
  const [aosWidth, setAosWidth] = useState(false)
  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.outerWidth < 768) {
        setAosWidth(true)
      } else {
        setAosWidth(false)
      }
    })
  }, [aosWidth])
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      maxW="900px"
      margin="auto"
      mt="50px"
      pt="70px"
      className="technology sideMenuId"
      id="technologyId"
    >
      <Box p="15px" data-aos={!aosWidth ? "fade-right" : "fade-up"}>
        <Text color="#8D448B" fontSize="16px">
          Behind the design
        </Text>
        <Text
          fontSize="44px"
          color="#0F2137"
          lineHeight="60px"
          fontWeight="700"
        >
          Code that we use to build solutions
        </Text>
        <Text
          color="#02073E"
          fontSize="18px"
          lineHeight="32px"
          mt="50px"
          mb="15px"
        >
          In today's competitive world, expectation has gone very high. We keep
          ourselves updated with latest and most efficient technologies to
          provide best services to our clients.
        </Text>
      </Box>
      <Image
        data-aos={!aosWidth ? "fade-left" : "fade-up"}
        w="55%"
        src={technology}
      />
    </Box>
  )
}

export default Technology
