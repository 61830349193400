import React, { useEffect, useState } from "react"

import { Box } from "@chakra-ui/layout"

import { Text } from "@chakra-ui/layout"
import { Button } from "@chakra-ui/button"
import Question from "./Question"
import "./Faq.css"
import { Questions } from "./FaqData"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const Faq = () => {
  const [aosWidth, setAosWidth] = useState(false)

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.outerWidth < 768) {
        setAosWidth(true)
      } else {
        setAosWidth(false)
      }
    })
  }, [aosWidth])

  const limitQuestion = Questions.slice(0, 5)

  return (
    <Box
      maxW="940px"
      margin="auto"
      my="100px"
      p="15px"
      id="faqId"
      className="sideMenuId"
    >
      <Box data-aos="fade-up">
        <Text
          color="var(--secondary-headingColor)"
          fontSize="var(--secondary-heading)"
          textAlign="center"
          fontWeight="700"
        >
          Frequently Asked Questions
        </Text>
        <Text color="#858B91" fontSize="16px" textAlign="center">
          Ask your question and meet
        </Text>
      </Box>
      <Box
        mt="60px"
        display="flex"
        alignItems="center"
        className="faq__container"
      >
        <Box
          data-aos={!aosWidth ? "fade-right" : "fade-up"}
          className="faq__content"
        >
          <Text color="#0F2137" fontSize="24px" fontWeight="500">
            Do you have any question? Please ask here we ready to support
          </Text>
          <Text color="#343D48" mt="10px" mb="25px">
            If your question is not listed here, please feel free to make a
            manual support.
          </Text>
          <Button colorScheme="#02073e" className="btn__primary">
            <AnchorLink to="/contact">Ask your Question</AnchorLink>
          </Button>
        </Box>
        <Box
          data-aos={!aosWidth ? "fade-left" : "fade-up"}
          className="faq--question__container"
        >
          {limitQuestion.map(item => (
            <Question
              key={item.id}
              item={item}
              title={item.title}
              description={item.description}
            />
          ))}
          <Box display="flex" justifyContent="flex-end">
            <AnchorLink to="/faq">
              <Button mr="20px" colorScheme="#02073e" className="btn__primary">
                More FAQS
              </Button>
            </AnchorLink>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Faq
