import React, { useEffect, createRef } from "react"

import { Box } from "@chakra-ui/layout"
import animation from "./header_img.json"
import lottie from "lottie-web"
import "./Animation.css"

const HeaderAnimation = () => {
  let animationContainer = createRef()
  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animation,
    })
    return () => anim.destroy() // optional clean up for unmounting
  }, [animationContainer])
  return <Box className="animation" ref={animationContainer} />
}

export default HeaderAnimation
