import React, { useEffect } from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import "./SideMenu.css"

const SideMenu = () => {
  useEffect(() => {
    const sectionList = document.querySelectorAll(".sideMenuId")
    const linkList = document.querySelectorAll(".side__menu ul li")

    window.addEventListener("scroll", () => {
      let current = ""
      sectionList.forEach(section => {
        const sectionTop = section.offsetTop
        const sectionHeight = section.clientHeight
        if (window.pageYOffset >= sectionTop - sectionHeight / 3) {
          current = section.getAttribute("id")
        }
      })
      linkList.forEach(link => {
        link.classList.remove("active")
        if (link.classList.contains(current)) {
          link.classList.add("active")
        }
      })
    })
  }, [])

  // console.log(linkList)

  return (
    <div className="side__menu">
      <ul>
        <li className="headerId active">
          <AnchorLink to="#headerId">&nbsp;</AnchorLink>
        </li>
        <li className="serviceId">
          <AnchorLink to="#serviceId">&nbsp;</AnchorLink>
        </li>
        <li className="technologyId">
          <AnchorLink to="#technologyId">&nbsp;</AnchorLink>
        </li>
        <li className="technologyStackId">
          <AnchorLink to="#technologyStackId">&nbsp;</AnchorLink>
        </li>
        <li className="timelineId">
          <AnchorLink to="#timelineId">&nbsp;</AnchorLink>
        </li>
        <li className="featureId">
          <AnchorLink to="#featureId">&nbsp;</AnchorLink>
        </li>
        <li className="faqId">
          <AnchorLink to="#faqId">&nbsp;</AnchorLink>
        </li>
      </ul>
    </div>
  )
}

export default SideMenu
