import { Spacer } from "@chakra-ui/layout"
import { Box } from "@chakra-ui/layout"
import React from "react"
import HeaderAnimation from "../../../animations/HeaderAnimation"
import Layout from "../Layout/Layout"
import SideMenu from "../SideMenu/SideMenu"
import "./Header.css"

const Header = ({ showLogin = false }) => {
  return (
    <Layout showLogin={showLogin}>
      <Box
        data-aos="fade-down"
        className="header sideMenuId"
        align="center"
        pt="10px"
        id="headerId"
      >
        <Spacer />
        <h1 className="header__heading">
          Grow your business with <br /> smart technology solutions
        </h1>
        <Spacer />
        <HeaderAnimation />
      </Box>
      <SideMenu />
    </Layout>
  )
}

export default Header
